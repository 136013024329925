<script>
import { nextTick } from "vue";
import PopupInvoice from '@/components/popupInvoice/index.vue'
import axios from "axios";

let frameRwq
export default {
	name: "tradition",
	computed: {
		isTimesUp(){
			return this.$store.state.isTimesUp
		},
		hostUrl(){
			return this.$store.state.hostUrl;
		},
		token(){
			return this.$store.state.token;
		},
		userNTInfo(){
			return this.$store.state.userNTInfo;
		},
	},
	components: {
		PopupInvoice
	},
	data(){
		return{
			invoiceType: "", //用來顯示何種發票表單
			isScanOver: false, //判斷是否顯示電子發票表單
			userInvoiceData: {},
			isLock: false,
			errorClass: {},
			isActivePopupInvoice: false
		}
	},
	beforeMount() {
		let vm = this;
		vm.invoiceType=vm.$route.query.type
	},
	mounted() {
		nextTick(() => {
			let vm = this;
			if(vm.invoiceType==="einvoice"){
				vm.main()
			}
		});
	},
	beforeRouteLeave (to, from) {
		let vm = this;
		if(from.query.type==="einvoice" && document.querySelector('video')){
			vm.scanOver()
		}
	},
	methods:{
		scanOver(){
			console.log("scanOver")
			let vm=this
			document.querySelector("video").pause()
			cancelAnimationFrame(frameRwq)
			nextTick(()=>{
				vm.isScanOver=true
			})
		},
		checkInput(target){
			// console.log("checkInput",target)
			let vm=this

			var validInvoice = /^[a-zA-Z]{2}[-]?[0-9]{8}$/;
			if (vm.userInvoiceData.number === ""||!vm.userInvoiceData.number||!validInvoice.test(vm.userInvoiceData.number)) {
				vm.errorClass.number=true
			}else{
				vm.errorClass.number=false
			}

			if (vm.userInvoiceData.date === ""||!vm.userInvoiceData.date) {
				vm.errorClass.date=true
			}else{
				vm.errorClass.date=false
			}

			if (vm.userInvoiceData.preview === ""||!vm.userInvoiceData.preview) {
				vm.errorClass.preview=true
			}else{
				vm.errorClass.preview=false
			}

			if(target==="einvoice"){
				if (vm.userInvoiceData.random_code === ""||!vm.userInvoiceData.random_code||vm.userInvoiceData.random_code.length !== 4) {
					vm.errorClass.random_code=true
				}else{
					vm.errorClass.random_code=false
				}
			}
			else if(target==="carrier"){
				var validNumber = /^\/[0-9A-Za-z.+-]{7}$/;
				if (vm.userInvoiceData.cardNo === ""||!vm.userInvoiceData.cardNo||!validNumber.test(vm.userInvoiceData.cardNo)) {
					vm.errorClass.cardNo=true
				}else{
					vm.errorClass.cardNo=false
				}

				if (vm.userInvoiceData.cardEncrypt === ""||!vm.userInvoiceData.cardEncrypt) {
					vm.errorClass.cardEncrypt=true
				}else{
					vm.errorClass.cardEncrypt=false
				}
			}

			let array = Object.keys(vm.errorClass)
				.map(function(key) {
					return vm.errorClass[key];
				});

			if(array.indexOf(true)!==-1){
				return
			}
			
			vm.sendInvoice()
			
		},
		sendInvoice(){
			console.log("sendInvoice")
			let vm=this

			vm.$store.commit('loadingToggle',true)
			vm.isLock=true	

			let data={}

			switch (vm.invoiceType) {
			case "einvoice":
				data= {
					type: "invoice",
					invnum: vm.userInvoiceData.number,
					invdate: vm.userInvoiceData.date,
					rndcode: vm.userInvoiceData.random_code,
					invoice_file: vm.userInvoiceData.preview,
				};
				break;
			case "carrier":
				data= {
					type: "card",
					invnum: vm.userInvoiceData.number,
					invdate: vm.userInvoiceData.date,
					barcode: vm.userInvoiceData.cardNo,
					barcode_verify: vm.userInvoiceData.cardEncrypt,
					invoice_file: vm.userInvoiceData.preview,
				};
				break;
			case "tradition":
				data= {
					type: "tradition",
					invnum: vm.userInvoiceData.number,
					invdate: vm.userInvoiceData.date,
					invoice_file: vm.userInvoiceData.preview,
				};
				break;
			}

			axios.post(vm.hostUrl+"api/user/invoice",data,
				{
					headers: {
						"Authorization": `Bearer ${vm.token}`,
						"Accept": "application/json",
					},
				})
				.then(function (response) {
					console.log(response)
					if(response.data.success){
						vm.$store.dispatch("getUserNTInfo").then(()=> {
							setTimeout(() => {
								vm.isActivePopupInvoice=true
								vm.isLock=false
							}, 2500);
						})
					}
				})
				.catch(function (error) {
					alert(error.response.data.error.message)
					vm.$store.commit('loadingToggle',false)
					vm.isLock=false
				});
		},
		uppercase() {
			// console.log("uppercase")
			let vm=this
			vm.userInvoiceData.number = vm.userInvoiceData.number.toUpperCase();
		},
		//上傳圖片預覽
		previewImage(event) {
			// console.log("previewImage");
			var input = event.target;
			let vm=this
			if (input.files && input.files.length > 0) {
				var reader = new FileReader();
				reader.onload = (e) => {
					var img = new Image();
					img.onload = function () {
						vm.mixCanvas(img);
					};
					img.src = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
		//圖片壓縮
		mixCanvas(img) {
			// console.log("mixCanvas");
			let vm=this
			var canvasPic = document.getElementsByClassName("canvas-pic")[0]
			var ctx = canvasPic.getContext("2d");
			canvasPic.width = 1000;
			canvasPic.height = img.height * (1000 / img.width);
			ctx.drawImage(img, 0, 0, canvasPic.width, canvasPic.height);
			vm.userInvoiceData.preview= canvasPic.toDataURL("image/jpeg")
		},
		async main() {
			// console.log("main")
			let vm=this
			let video = document.querySelector('video');
			try {
				video.srcObject = await navigator.mediaDevices.getUserMedia(constraints)
				video.setAttribute("playsinline", true);
				await video.play();
				requestAnimationFrame(vm.tick);
			} catch (error) {
				alert("您拒絕授權使用相機")
			}
		},
		tick() {
			// console.log("tick")
			let vm=this
			const video = document.querySelector('video');
			const canvasElement = document.getElementById("invoiceCanvas");
			const ctx = canvasElement.getContext("2d");

			if (video.readyState === video.HAVE_ENOUGH_DATA) {
				canvasElement.hidden = false;
				canvasElement.width = video.videoWidth;
				canvasElement.height = video.videoHeight;
				ctx.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
				let img = ctx.getImageData(0, 0, canvasElement.width, canvasElement.height);
				const code = jsQR(img.data, img.width, img.height, { inversionAttemps: "dontInvert" });
				if (code) {
					let found = {
						'number': "",
						'date': {
							'raw': "",
							'format': "",
							'y': "",
							'm': "",
							'd': "",
						},
						'random_code': ""
					};
					let str = code.data;

					found.number = str.substring(0, 10);
					found.date.raw = str.substring(10, 17);
					found.date.y = parseInt(found.date.raw.substring(0, found.date.raw.length - 4)) + 1911;
					found.date.y = found.date.y.toString();
					found.date.m = found.date.raw.substring(found.date.raw.length - 4, found.date.raw.length - 2);
					found.date.d = found.date.raw.substring(found.date.raw.length - 2, found.date.raw.length);
					found.date.format = found.date.y + "-" + found.date.m + "-" + found.date.d
					found.random_code = str.substring(17, 21);
					if (found.number && found.number.match(/[A-Z]{2}\d{8}/) && found.random_code.match(/\d{4}/)) {
						vm.stopMedia(found)
						return;
					} 
				}
			}
			frameRwq=requestAnimationFrame(vm.tick);
		},
		stopMedia(found) {
			// console.log("stopMedia")
			let vm=this
			let e = document.getElementById("invoiceCanvas"),
				n = document.querySelector("video");

			n.pause();

			vm.userInvoiceData = {
				type: "invoice",
				number: found.number,
				date: found.date.format,
				random_code: found.random_code,
				invoice_file: e.toDataURL("image/png"),
				preview: e.toDataURL("image/png")
			};

			vm.isScanOver=true
		},
	}
}


var constraints = {
	audio: !1,
	video: {
		width: {
			min: 400,
			ideal: 720,
			max: 1080
		},
		height: {
			min: 400,
			ideal: 720,
			max: 1080
		},
		aspectRatio: 1.25,
		facingMode: "environment"
	}
};


</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>