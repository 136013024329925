<script>
export default {
	name: "PopupInvoice",
	props:{
		isActivePopupInvoice:{
			type: Boolean,
			required: true,
		}
	},
	computed: {
		isTimesUp(){
			return this.$store.state.isTimesUp
		},
	},
	data(){
		return{
		}
	},
	methods: {
		closePopup(){
			// console.log("closePopup")
			let vm=this
			vm.$emit("update:isActivePopupInvoice", false);
		},
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>